import { cache } from 'swr'
import { LoginFormTypes, PassRecoveryFormTypes, PassResetFormTypes } from '../components/Form/form-types';
import api from '../services/api';
import { deleteCookie, setCookie } from '../services/utils';
import { getProfile } from './profile';

export const login = (data: LoginFormTypes) => {
    return api.post('/login', data).then(async (res) => {
        const token = res.data.data.token;
        api.defaults.headers['Authorization'] = `Bearer ${token}`;
        setCookie('jid', token, 30);
        const profile = await getProfile();
        cache.set('profile', profile)
        return profile;
    });
};

export const refreshToken = async () => {
    const token = localStorage.getItem('jid');
    if(token) {
        await api.post('/refresh', {}).then(async (res) => {
            const token = res.data.data.token;
            api.defaults.headers['Authorization'] = `Bearer ${token}`;
            setCookie('jid', token, 30);
        });
    }
}

export const logout = () => {
    return api.post('/logout').then((_) => {
        deleteCookie('jid');
    });
};

export const passwordRecovery = (data: PassRecoveryFormTypes) => {
    return api.post('/forgotten-password/send-email', data);
};

export const passwordReset = (data: PassResetFormTypes) => {
    return api.post('/forgotten-password/reset-password', data);
};
